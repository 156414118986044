@import 'styles/variables.scss';

.product-card {
    .product-cover {
        overflow: hidden;

        img {
            width: 12.5rem;
            height: 12.5rem;
            object-fit: contain;
        }
    }

    .product-badge {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: $red-badge;
        border-color: $red-badge;
        color: white;
        padding: 0.375rem 0.5rem;
        font-size: 0.85rem;
    }

    .book-type {
        font-size: 0.85rem;
        color: $gray-500;
    }

    .sales-description {
        color: $primary;
        font-size: 1rem;
        font-weight: bolder;
    }

    .price-amount {
        color: black;
        font-size: 0.85rem;
        font-weight: 600;
    }

    .product-description {
        color: black;
        font-size: 0.85rem;
        font-weight: 500;
    }
}

