.button-add-cart {
    span {  
        font-size: 0.85rem;
        font-weight: 700;
        display: block;
        position: relative;
        padding-left: 18px;
        line-height: 26px;
        transition: transform 0.5s ease;
     }
 }