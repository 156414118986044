
.customer-registration {
    .loading-server {
        position:absolute;
        left:0;
        top:0;
        width: 100vw;
        height: 100vh;
        z-index: 999;
        background-color: rgb(255,255,255,0.6);
    }

    .field-error-text {
        min-height: 1.5rem;
    }
}