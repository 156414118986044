@import 'styles/variables.scss';

.photo-student {
    .photo-frame {
        width: 8rem;
        height: 8rem;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .image-permission {
        font-size: 0.875rem;
        
        a {
            color: white;
            text-decoration: underline !important;
        }
    }

    .cursor-pointer {
        input[type="checkbox"] {
            cursor: pointer !important;
        }

        label {
            cursor: pointer !important;
        }
    }
}

.photo-student-modal {
    .photo-student-preview {
        max-height: 67vh;
    }

    .modal-content {
        color: white;
        background: #26199F; //#4B3BE0;
        background-size: 280px;
        background-repeat: repeat;
        background-position: top;
        background-image: url('../../../assets/images/ik_pattern_purple.svg') !important;
    }
    
}

