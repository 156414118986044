$spacer: 1rem !default;
$spacers: (
  0: 0,
  // 0
  1: $spacer * 0.25,
  // 4
  2: $spacer * 0.5,
  // 8
  3: $spacer * 0.75,
  // 12
  4: $spacer,
  // 16
  5: $spacer * 1.25,
  // 20
  6: $spacer * 1.5,
  // 24
  7: $spacer * 1.75,
  // 28
  8: $spacer * 2,
  // 32
  9: $spacer * 2.25,
  // 36
  10: $spacer * 2.5,
  // 40
  11: $spacer * 2.75,
  // 44
  12: $spacer * 3,
  // 48
  13: $spacer * 3.25,
  // 52
  14: $spacer * 3.5,
  // 56
  15: $spacer * 3.75,
  // 60
  16: $spacer * 4,
  // 64
  17: $spacer * 4.5,
  // 72
  18: $spacer * 5,
  // 80
) !default;
