@import 'styles/variables.scss';

.create-account {
    width: 100%;
    min-height: 100vh;
    background: #26199F; //#4B3BE0;
	background-size: 280px;
  	background-repeat: repeat;
    background-position: top;
  	background-image: url('../../../assets/images/ik_pattern_purple.svg') !important;

    .auth-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
    }
    .navbar-light {
        background-color: #ffffff;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    }
    .auth-inner {
        width: 90%;
        padding: 40px 35px 35px 35px;

        @include media-breakpoint-up(sm) {
            width: 450px;
            padding: 40px 55px 35px 55px;
        }

        margin: auto;
        background: #ffffff;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
        border-radius: 15px;
        transition: all .3s;
    }
    .auth-wrapper .form-control:focus {
        border-color: #167bff;
        box-shadow: none;
    }
    .auth-wrapper h3 {
        text-align: center;
        margin: 0;
        line-height: 1;
        padding-bottom: 20px;
    }
    .custom-control-label {
        font-weight: 400;
    }
    .forgot-password,
    .forgot-password a {
        text-align: right;
        font-size: 13px;
        padding-top: 10px;
        color: #7f7d7d;
        margin: 0;
    }
    .forgot-password a {
        color: #167bff;
    }

    .field-error {
        font-size: 0.8125rem;
    }

    .show-password {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 1rem;
        color: #C4C4C4;
        cursor: pointer;
    }

    .loading-create-account {
        position:absolute;
        left:0;
        top:0;
        width: 100vw;
        height: 100vh;
        z-index: 999;
        background-color: rgb(255,255,255,0.6);
    }
}