@import 'styles/variables.scss';

.login-layout {
    display: flex;
    flex: auto;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    min-height: 100vh;
}
