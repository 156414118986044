@import 'styles/variables.scss';

.header {
    .icon-button:hover {
        opacity: 0.85;
    }

    .student-item {
        .form-check-label, .form-check-input {
            cursor: pointer;
        }
    }
}
