
// $imagina-blue: #56BEE3;
$imagina-blue: #11cdef;
$imagina-blue-light: #b3e5f1;
$imagina-yellow: #FFCD0A;
$red-pink: #f5365c;
$red-pink-light: #f38a9f;
$imagina-green: #78C3B2;

$purple: #5e72e4;
$purple-light: #7d8bdb;
$purple-dark: #505a89;

$red-badge: #D10024;

$white: #fff !default;
$gray-100: #f2f2f2 !default;
$gray-200: #e5e5e5 !default;
$gray-300: #d9d9d9 !default;
$gray-400: #c9c9c9 !default;
$gray-500: #b3b3b3 !default;
$gray-600: #666666 !default;
$gray-700: #4d4d4d !default;
$gray-800: #333333 !default;
$gray-900: #1a1a1a !default;
$black: #000 !default;

$primary: $purple !default;
$primary-light: $purple-light !default;

$danger: $red-pink !default;
$danger-light: $red-pink-light !default;

$warning: $imagina-yellow !default;
