@import 'styles/variables.scss';

.header {
    font-weight: 600;
    color: white;

    background: #26199F; //#4B3BE0;
	background-size: 280px;
  	background-repeat: repeat;
    background-position: top;
  	background-image: url('../../assets/images/ik_pattern_purple.svg') !important;

    .add-student-button {
        @include media-breakpoint-down(sm) {
            display: inline-block;
            width: 100%;
        }
    }

    .card-students {
        @include media-breakpoint-down(sm) {
            display: inline-block;
            width: 100%;
        }
    }

    .badge-cart {
        width: 1.25rem;
        height: 1.25rem;
    }
}
