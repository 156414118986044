
.order-history-dropdown {
    
    .dropdown-menu {
        max-height: 11rem;
        min-width: 12.5rem;
        max-width: 20rem;
        overflow-y: scroll;
        padding: 0;
    }

    .dropdown-item:hover {
        background-color: #e5e5e5;
    }

    .dropdown-item {
        height: 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .dropdown-item.active {
        background-color: inherit;
        color: inherit;
    }

    .dropdown-item.active:hover {
        background-color: #e5e5e5;
    }

    .order-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}