@import 'styles/variables.scss';

.review-order-container {
    .loading-server {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999;
        background-color: rgb(255,255,255,0.6);
    }

    .divider {
        display: block;
        width: 100%;
        border-bottom: 1px solid $gray-300;
    }

    .customer-info {
        font-size: 0.875rem;
    }
}