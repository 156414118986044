@import 'styles/variables.scss';

.modal-close-button {
    position: absolute;
    background: none;
    right: 0;
    top: 0;
    margin: 1rem 2rem;

    @include media-breakpoint-up(md) {
        margin: 2.5rem 4rem;
    }
}