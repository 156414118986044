@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Montserrat:wght@300;400;500&family=Open+Sans:wght@300;400;600;700;800&family=Roboto&display=swap');

@import 'styles/variables.scss';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* Disable Android highlight */
    min-height: -webkit-fill-available;
    min-height: 100vh;
    min-width: 100vw;
    height: -webkit-fill-available; /* We have to fix html height */
    display: flex;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    /* font-size: 62.5%; */
    font-size: 100%;
    // font-family: 'Montserrat', sans-serif;
    font-family: 'Open Sans', sans-serif;
    scroll-behavior: smooth;
}

#root {
    min-height: 100vh;
    display: flex;
    flex: auto;
    flex-direction: column;
}

.spinner-border {
    width: 40px; 
    height: 40px;
}

a {
    text-decoration: none !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1 !important;
}

button {
    font-weight: 700 !important;
    border: 0 !important;

    &.btn-sm {
        font-size: 0.75rem;
    }
}

.error-container {
    background-color: #ffd9da;
    border: solid 1px #ee0306;
}

.error-message {
    color: #ee0306 !important;
}

.success-container {
    background-color: #e9f2dc;
    border: solid 1px #c3d8a1;
}

.success-message {
    color: #62916c !important;
}

.rounded-stepper {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    color: white;
}

#RFS-StepperContainer {
    .completed {
        background-color: $purple-dark;
    }

    .active {
        background-color: $purple;
    }
}

#RFS-ConnectorContainer {
    top: calc((2em - 1px) / 2);
    left: calc((-50% + 2em) - 8px);
    right: calc((50% + 2em) - 8px);
    position: absolute;
}

#RFS-Connector {
    display: block;
    border-color: rgb(189, 189, 189);
    border-top-style: solid;
    border-top-width: 1px;
}

[id^=react-joyride-step] {
    max-width: 0 !important;
}

[data-tooltip] {
    position: relative;
    z-index: 30;

    &::before,
    &::after {
        opacity: 0 !important;
        visibility: hidden;
        margin-top: -0.5em;
    }

    &::before {
        content: attr(data-tooltip);
        display: block;
        position: absolute;
        top: 0;
        left: 50%; 
        transform: translate(-50%, -100%);
        width: 8em;
        text-align: center;
        border-radius: 4px;
        background: $gray-800;
        color: white;
        font-size: 0.8em;
        line-height: 1.1;
        padding: 0.75em 0.95em;
    }

    &::after {
        content: "";
        border: 10px solid transparent;
        border-top-color: $gray-800;
        position: absolute;
        top: -3px;
        left: 50%;
        margin-left: -10px;
    }

    &:hover {
        cursor: pointer;
    }

    &:hover::before,
    &:hover::after {
        opacity: 1 !important;
        visibility: visible;
    }
}
