@import 'styles/variables.scss';

.product-list {
    .product-container {
        .product-col {
            .product-card {
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 3rem 3rem 0 0;
                    border-color: transparent transparent transparent transparent;
                }
            }
        }

        .product-col:nth-child(4n+1) {
            .product-card {
                border-color: $imagina-blue !important;
                &::after {
                    border-color: $imagina-blue transparent transparent transparent;
                }
            }
        }
        .product-col:nth-child(4n+2) {
            .product-card {
                border-color: $imagina-yellow !important;
                &::after {
                    border-color: $imagina-yellow transparent transparent transparent;
                }
            }
        }
        .product-col:nth-child(4n+3) {
            .product-card {
                border-color: $red-pink !important;
                &::after {
                    border-color: $red-pink transparent transparent transparent;
                }
            }
        }
        .product-col:nth-child(4n+4) {
            .product-card {
                border-color: $imagina-green !important;
                &::after {
                    border-color: $imagina-green transparent transparent transparent;
                }
            }
        }
    }

    .information-image {
        max-height: 18rem;
        object-fit: contain;
    }
}

.floating-button {
    display: block;
    position: fixed;
    right: 0.85rem;
    bottom: 2rem;
    width: 3.5rem;
    height: 3.5rem;
    background-color: $primary;
    color: white;
    border-radius: 50%;
    z-index: 30;
    border: 3px solid white !important;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}