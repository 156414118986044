@import 'styles/variables.scss';

.checkout-container {
    .checkout-section {
        &::before {
            content: attr(data-title);
            display: block;
            width: 100%;
            background-color: $imagina-yellow;
            padding: 0.5rem 1rem;
            margin: 1rem 0;
            font-weight: bolder;
            font-size: 1rem;
        }
    }

    .table > thead > tr {
        padding: 8px;
        border-top: 1px solid var(--bs-table-border-color);
    }

    .thumb-product {
        max-height: 4.5rem;
        object-fit: contain;
    }

    .product-title {
        font-size: 1rem;
    }

    .product-student-name {
        font-size: 0.875rem;
    }

    .product-amount {
        font-size: 0.75rem;
    }

    .icon-button:hover {
        opacity: 0.5;
    }

    .product-price {
        font-size: 0.875rem;
        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
    }

    .product-subprice {
        max-width: 6.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product-total-price {
        max-width: 15rem; // 16.25rem
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .required .control-label::after { 
        color: #d00;
        content: "*";
        margin-left: 2px;
    }
}