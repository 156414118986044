
.modal-addstudent {
  .modal-content {
    color: white;
    background: #26199F; //#4B3BE0;
    background-size: 280px;
    background-repeat: repeat;
    background-position: top;
    background-image: url('../../assets/images/ik_pattern_purple.svg') !important;

    .modal-close-button {
      color: white;
    }
  }

  input[type="text"] {
    height: 3.5rem;
  }
}