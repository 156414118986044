@import 'styles/variables.scss';

.waiting-image {
    max-height: 20rem;
    object-fit: contain;
}

.loader-container {
    margin-top: -60px;
}

.box{
    width:15px;
    height:18px;
    background: white;
    margin:5px;
    border-radius: 0.1rem;
}
.box:nth-child(1){
    background: $red-pink;
    animation: balls 0.5s linear infinite;
}
.box:nth-child(2){
    background: $purple;
    animation: balls 0.5s  0.1s linear infinite;
}
.box:nth-child(3){
    background:$imagina-blue;
    animation: balls 0.5s 0.2s linear infinite;
}
.box:nth-child(4){
    background:$imagina-yellow;
    animation: balls 0.5s 0.4s linear infinite;
}
  
@keyframes balls{
    0%{
      transform:scaleY(1);
    }
    50%{
      transform:scaleY(3);
    }
    100%{
      transform:scaleY(1);
    }
}
