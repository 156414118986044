
.cart-body {

    // .table {
    //     max-width: 100% !important;
    // }

    .table > thead > tr {
        padding: 8px;
        border-top: 1px solid var(--bs-table-border-color);
    }

    .thumb-product {
        max-height: 2.4rem;
        object-fit: contain;
    }

    .product-title {
        font-size: 1rem;
    }

    .product-student-name {
        font-size: 0.875rem;
    }

    .product-amount {
        font-size: 0.75rem;
    }

    .product-subprice {
        max-width: 6.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product-price {
        max-width: 15rem; // 16.25rem
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon-button:hover {
        opacity: 0.5;
    }
}