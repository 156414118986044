@import 'styles/variables.scss';

.payment-method-container {
    .pix-logo {
        max-width: 16px;
        max-height: 16px;
    }

    
    svg {
        color: $primary;
        fill: $primary;
        box-sizing: content-box;
    }
    

    .nav-link.active {
        svg {
            color: white;
            fill: white;
            box-sizing: content-box;
        }
    }
}