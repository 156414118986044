@import 'styles/variables.scss';

.payment-method-container {
    min-height: 35.125rem;

    .payment-installment-select {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .loading-server {
        position: absolute;
        left:0;
        top:0;
        width: 100vw;
        height: 100vh;
        z-index: 999;
        background-color: rgb(255,255,255,0.6);
    }

    .field-error-text {
        min-height: 1.5rem;
    }

    .payment-loader {
        width : 150px;
        position: absolute;
        top: 0%;
        left : 50%;
        -webkit-transform: translateY(-50%) translateX(-50%);
        -moz-transform: translateY(-50%) translateX(-50%);
        -o-transform: translateY(-50%) translateX(-50%);
        transform: translateY(-70%) translateX(-50%);
      }
      
      .payment-loader .binding {
        content : '';
        width : 60px;
        height : 4px;
        border : 2px solid $primary;
        margin : 0 auto;
      }
      
      .payment-loader .pad {
        width : 60px;
        height : 38px;
        border-radius : 8px;
        border : 2px solid $primary;
        // padding : 6px;
        padding : 0;
        margin : 0 auto;
        background-color: white;
      }
      
      .payment-loader .chip {
        width : 12px;
        height: 8px;
       background:$primary;
        border-radius: 3px;
        margin-top: 4px;
        margin-left: 3px;
      }
      
      .payment-loader .line {
        width : 52px;
        margin-top : 6px;
        margin-left : 3px;
        height : 4px;
       background:$primary; border-radius: 100px;
        opacity : 0;
        -webkit-animation : writeline 3s infinite ease-in;
        -moz-animation : writeline 3s infinite ease-in;
        -o-animation : writeline 3s infinite ease-in;
        animation : writeline 3s infinite ease-in;
      }
      
      .payment-loader .line2 {
        width : 32px;
        margin-top : 6px;
        margin-left : 3px;
        height : 4px;
           background : $primary;
        border-radius: 100px;
        opacity : 0;
        -webkit-animation : writeline2 3s infinite ease-in;
        -moz-animation : writeline2 3s infinite ease-in;
        -o-animation : writeline2 3s infinite ease-in;
        animation : writeline2 3s infinite ease-in;
      }
      
      .payment-loader .line:first-child {
        margin-top : 0;
      }
      
      .payment-loader .line.line1 {
        -webkit-animation-delay: 0s;
        -moz-animation-delay: 0s;
        -o-animation-delay: 0s;
        animation-delay: 0s;
      }
      
      .payment-loader .line.line2 {
        -webkit-animation-delay: 0.5s;
        -moz-animation-delay: 0.5s;
        -o-animation-delay: 0.5s;
        animation-delay: 0.5s;
      }
      
      .payment-loader .loader-text {
        
      
        
        p:nth-child(1){
            color : $primary;
        font-size : 16px;
          margin-top:5px;
          margin-bottom:0px;
           overflow: hidden; 
        $primary-space: nowrap;
      
            animation-delay: 10s;
          animation : textline1 3s infinite ease-in;
        }
        p:nth-child(2){
          margin-top:10px;
          color:$primary;
          font-size:14px;
           overflow: hidden; 
        $primary-space: nowrap;
          text-align:center;
            animation-delay: 2s;
          animation : textline2 3s infinite ease-in;
        }
      }
      
      
      @keyframes writeline {
        0% { width : 0px; opacity: 0; }
        33% { width : 52px; opacity : 1; }
        70% { opacity : 1; }
        100% {opacity : 0; }
      }
      
      @keyframes writeline2 {
        0% { width : 0px; opacity: 0; }
        33% { width : 32px; opacity : 1; }
        70% { opacity : 1; }
        100% {opacity : 0; }
      }
      @keyframes textline1{
        0% { width : 0%; opacity: 0;  }
        33% { width : 100%; opacity : 1; }
        70% { opacity : 1; }
        100% {opacity : 0; }
      }
      @keyframes textline2{
        0% { width : 100%; opacity: 0;  }
        33% { width : 100%; opacity : 1; }
        70% { opacity : 1; }
        100% {opacity : 0; }
      }
      
}